import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import image from '../img/kontaktbild.jpg';
import TextAndImagePage from '../components/TextAndImagePage';
import Text from '../components/Text';

const ToiletTrainingPage: React.FC<{ data: any }> = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <TextAndImagePage
        title={post.frontmatter.title}
        content={<Text>{post.frontmatter.text}</Text>}
        image={image}
        backLink="/konzept"
      />
    </Layout>
  );
};

export default ToiletTrainingPage;

export const query = graphql`
  query ToiletTrainingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        text
      }
    }
  }
`;
