import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import BackButton from './BackButton';
import PageTitle from './PageTitle';

interface Props {
  title: string;
  content: React.ReactNode;
  image: string;
  backLink?: string;
  backLinkText?: string;
}

const TextAndImagePage: React.FC<Props> = ({
  title,
  content,
  image,
  backLink,
  backLinkText,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 860px)' });

  return (
    <Container>
      <div style={{ flex: 1 }}>
        <PageTitle>{title}</PageTitle>
        {content}
        {!isMobile && backLink && (
          <BackButton
            to={backLink}
            style={{ marginTop: '30px' }}
            text={backLinkText}
          />
        )}
      </div>
      <Image src={image} />
      {isMobile && backLink && (
        <BackButton
          to={backLink}
          style={{ marginTop: '30px' }}
          text={backLinkText}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 860px) {
    flex-direction: row;
  }
`;

const Image = styled.img`
  height: 100%;
  margin-top: 30px;

  @media (min-width: 860px) {
    margin-top: 0;
    margin-left: 50px;
  }
`;

export default TextAndImagePage;
